import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import type {
  ControllerFlowAPI,
  ISeo,
  ISiteApis,
  PlatformControllerFlowAPI,
  SiteStructure,
} from '@wix/yoshi-flow-editor';
import type { PopulatedMenu, PopulatedSection } from 'root/apiTypes';
import type { MenuOrder } from 'root/components/Menus/Settings/Tabs/MenusTab/MenusSettingsParams';
import { MENUS_EXTENDED_FIELDS_NAME_SPACE } from 'root/consts';

const getBreadcrumbsConfig = ({
  siteStructure,
  baseUrl,
  t,
  site,
}: {
  siteStructure: SiteStructure;
  baseUrl: string;
  t: PlatformControllerFlowAPI['translations']['t'];
  site: ISiteApis;
}) => {
  const { pages: sitePages } = siteStructure;

  const homePage = {
    name: t('app.breadcrumbs.labels.home'),
    url: '/',
  };
  const currentPageId = site.currentPage?.id;
  const currentPage = sitePages.find((page) => page.id === currentPageId)!;

  return [
    {
      id: '1',
      value: homePage.name,
      href: `${baseUrl}${homePage.url}`,
    },
    {
      id: '2',
      value: currentPage.name ?? t('app.breadcrumbs.labels.menus'),
      href: `${baseUrl}${currentPage.url}`,
    },
  ];
};

export const generateBreadcrumbsSD = ({
  siteStructure,
  baseUrl,
  t,
  site,
}: {
  siteStructure: SiteStructure;
  baseUrl: string;
  t: PlatformControllerFlowAPI['translations']['t'];
  site: ISiteApis;
}) => {
  const breadcrumbs = getBreadcrumbsConfig({ siteStructure, baseUrl, t, site });
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((breadcrumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: breadcrumb.value,
      item: breadcrumb.href,
    })),
  };
};

export const getMenusSEOData = ({
  activeMenu,
  pageUrl,
  multilingual,
  menusDisplayOrder,
  firstMenu,
}: {
  activeMenu: PopulatedMenu;
  pageUrl: string;
  multilingual: ControllerFlowAPI['environment']['multilingual'];
  menusDisplayOrder?: MenuOrder[];
  firstMenu?: PopulatedMenu;
}) => {
  const menuItems = activeMenu.sections.flatMap((section: PopulatedSection) => section.items);
  const isMainMenu = menusDisplayOrder?.[0].id === activeMenu.id || firstMenu?.id === activeMenu.id;

  return {
    menu: {
      ...activeMenu,
      url: pageUrl,
      isMainMenu,
    },
    items: menuItems,
    translatedPages: multilingual,
  };
};

export const sendSEOData = async ({
  baseUrl,
  site,
  isCurrentPageMenusDefaultPage,
  multilingual,
  menusDisplayOrder,
  menus,
  seo,
  activeMenu,
  t,
}: {
  baseUrl: string;
  site: ISiteApis;
  isCurrentPageMenusDefaultPage: boolean;
  multilingual: ControllerFlowAPI['environment']['multilingual'];
  menusDisplayOrder?: MenuOrder[];
  menus: PopulatedMenu[];
  seo: ISeo;
  activeMenu: PopulatedMenu;
  t: PlatformControllerFlowAPI['translations']['t'];
}) => {
  const pageBaseUrl = baseUrl;
  const urlQueryParam = `?menu=${encodeURIComponent(activeMenu.urlQueryParam ?? '')}`;
  const siteUrl = site.currentPage?.url ?? '';
  const fullPageUrl = pageBaseUrl + siteUrl + urlQueryParam;
  const itemData = getMenusSEOData({
    activeMenu,
    pageUrl: fullPageUrl,
    multilingual,
    menusDisplayOrder,
    firstMenu: menus[0],
  });

  seo.renderSEOTags({
    itemType: isCurrentPageMenusDefaultPage ? ITEM_TYPES.RESTAURANTS_MENU_PAGE : ITEM_TYPES.RESTAURANTS_MENU_COMPONENT,
    itemData,
    seoData: activeMenu?.extendedFields?.namespaces?.[MENUS_EXTENDED_FIELDS_NAME_SPACE]?.seoData || {},
  });

  // Report breadcrumbs SD to seo
  const siteStructure = await site.getSiteStructure({
    includePageId: true,
  });
  const breadcrumbsSd = generateBreadcrumbsSD({ siteStructure, baseUrl, t, site });
  await seo.setStructuredData([breadcrumbsSd]);
};
